import Button from 'react-bootstrap/Button';
import { useState } from "react";
import { FormikErrors, useFormik } from "formik";
import useApi from 'system/hooks/use-api';

interface IProps {
  done: () => void;
}

interface ViewModel{
  email: string,
  role: 'User' | 'Admin'
}

export default function AccessForm( { done }: IProps ){
  const api = useApi();
  const [message, setMessage] = useState('');
  const form = useFormik({
    initialValues: {
      email: '',
      role: 'User'
    },
    validate: values => {
      const errors: FormikErrors<ViewModel> = {};
      if (!values.email) errors.email = 'Required';
      if (values.role !== 'User' && values.role !== 'Admin') errors.role = 'Must be User or Admin';
      return errors;
    },
    onSubmit: async values => {
      const result = await api.access.grant({
        email: values.email,
        role: values.role
      });
      if (result.isSuccess){
        done();
      } else {
        setMessage(result.failMessage ?? 'Error');
      }
      form.setSubmitting(false);
    }
  });

  return <div>
    <form className="a-form" onSubmit={form.handleSubmit}>
      <label>
        User's email address<br />
        <input name="email" value={form.values.email} onChange={form.handleChange} style={{width: '15em'}} />
      </label>
      { form.errors.email && <span className="warning">{form.errors.email}</span> }
      <label>
        Role<br />
        <select name="role" value={form.values.role} onChange={form.handleChange} style={{width: '15em', margin: '1px 1em 3px 2em'}}>
          <option>User</option>
          <option>Admin</option>
        </select>
      </label>
      {form.errors.role && <span className="warning">{form.errors.role}</span>}
    </form>
    <div className="warning">{message}</div>
    <div className="button-bar">
      <Button disabled={form.isSubmitting} onClick={() => form.submitForm()}>Save</Button>
      <Button variant="secondary" onClick={() => done()}>Cancel</Button>
    </div>
  </div>;
}