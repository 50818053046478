import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';
import { useState } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { AccessEntryInfo, SiteInfo, UploadKeyInfo } from 'system/types/wireTypes';
import SiteForm from "./SiteForm";
import { useRef } from "react";
import { v4 as generateUuid } from 'uuid';
import AccessForm from "./AccessForm";
import { getSorter } from 'system/libraries/utilities';
import useConfiguration from "system/hooks/use-configuration";
import useApi from "system/hooks/use-api";
import AdminReports from "./AdminReports";
import AskAidenAccess from "system/types/enums/AskAidenAccess";

//COMPONENT: displays tabs with admin content and the ability to edit users, etc

export default function AdminHome() {
  const api = useApi();
  const { currentTenant, userInfo } = useConfiguration();
  const editingFormKeyCounter = useRef(1);

  // Access state
  const [acl, setAcl] = useState<AccessEntryInfo[]>([]);
  const [accessDialogVisible, setAccessDialogVisible] = useState(false);

  //uploadkey state
  const [uploadKeys, setUploadKeys] = useState<UploadKeyInfo[]>([]);

  //site state
  const [sites, setSites] = useState<SiteInfo[]>([]);
  const siteToEdit = useRef<SiteInfo | null>(null);
  const [siteDialogVisible, setSiteDialogVisible] = useState(false);
  const [membershipLoading, setMembershipLoading] = useState(true);

  //manage tabs and functions to load the lists
  const loadAcl = async () => {
    setMembershipLoading(true);
    setAcl([]);
    const response = await api.access.getAcl();
    setAcl((response ?? []).sort(getSorter('role')).sort(getSorter('name')));
    setMembershipLoading(false);
  };

  const loadUploadKeys = async () => {
    const response = await api.admin.uploadKeys();
    setUploadKeys(response.items ?? []);
  };
  const loadSites = async () => {
    const response = await api.admin.sites();
    setSites(response.items ?? []);
  };
  const activeTabChanged = async (eventKey: string | null) => {
    if (eventKey === 'sites' && !sites.length) loadSites();
    else if (eventKey === 'uploadKeys' && !uploadKeys.length) loadUploadKeys();
    else if (eventKey === 'users' && !acl.length) loadAcl();
  };

  //access functions
  const grantAccess = () => {
    setAccessDialogVisible(true);
  };

  const endGrantAccess = () => {
    setAccessDialogVisible(false);
  };

  const revokeAccess = async (entry: AccessEntryInfo, force: boolean = false) => {
    if (!force && !window.confirm(`Are you sure you want to revoke ${entry.role} access from ${entry.name}?`)) {
      return;
    }
    const result = await api.access.revoke(entry);
    if (result.isSuccess) {
      setAcl(acl.filter(a => a !== entry));
    }
  };

  //uploadkey functions
  const createUploadKey = async () => {
    const response = await api.admin.addUploadKey(generateUuid());
    if (response.isSuccess) loadUploadKeys();
  };
  const deleteUploadKey = async (key23: string | null) => {
    if (!key23) return;
    const response = await api.admin.deleteUploadKey(key23);
    if (response.isSuccess) loadUploadKeys();
  }

  //site functions
  const startEditSite = (site: SiteInfo) => {
    editingFormKeyCounter.current += 1;
    siteToEdit.current = site;
    setSiteDialogVisible(true);
  }
  const endEditSite = () => {
    setSiteDialogVisible(false);
    loadSites();
  }
  const createSite = () => {
    const template: SiteInfo = {
      siteId: 0,
      actualMachineCount: 0,
      siteName: '',
      shortName: '',
      oldDeleteDays: 366,
      decommissionDays: 60,
      licensedMachineCount: 10000,
      licenseDays: 14,
      validDays: 7,
      isFileModuleEnabled: false,
      isSubjectStatusEnabled: false,
      customerShowLicenseFlag: 0,
      askAidenAccess: AskAidenAccess.NoAccess,
    };
    startEditSite(template);
  };
  const deleteSite = async (site: SiteInfo) => {
    await api.admin.deleteSite(site.siteId);
    loadSites();
  };

  //return truncated version if no permissions
  const hasPermissionU = currentTenant?.isAdmin ?? false;
  const hasPermissionC = userInfo?.permissions?.includes('C') ?? false;
  const hasPermissionG = userInfo?.permissions?.includes('G') ?? false;
  const hasPermissionR = userInfo?.permissions?.includes('R') ?? false;
  if (!hasPermissionU && !hasPermissionC && !hasPermissionG) return <div>You don't have any administrative permissions.</div>;

  return (
    <div className="admin-home">
      Site: {currentTenant?.siteName}
      <Tabs transition={false} onSelect={activeTabChanged}>
        <Tab eventKey="info" title="Basic">
          <p className="mt-3">Select a tab to configure:</p>
          <ul>
            {hasPermissionU && <li>Access: permissions for each email address that should have access</li>}
            {hasPermissionC && <li>Upload keys: Keys used by the Aiden endpoint utility to identify itself when uploading data</li>}
            {(hasPermissionC || hasPermissionG) && <li>Sites: Site-wide configuration</li>}
            {hasPermissionR && <li>Reports: AidenVision reporting</li>}
          </ul>
        </Tab>
        {hasPermissionU &&
          <Tab eventKey="users" title="Access">
            <div className="tab-content">
              <Button variant="secondary" onClick={() => grantAccess()}>Grant access</Button>&nbsp;
              <Button variant="secondary" onClick={() => loadAcl()}>Refresh</Button>
              {
                membershipLoading ?
                  <div>Loading</div> :
                  <table className="admin-table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {[...acl].sort(getSorter('name')).map(entry =>
                        <tr key={`${entry.userId}_${entry.role}`}>
                          <td>{entry.name}</td>
                          <td>{entry.email}</td>
                          <td>{entry.role}</td>
                          <td className="button-bar">
                            <Button variant="secondary" onClick={() => revokeAccess(entry)}>Revoke</Button>
                          </td>
                        </tr>)
                      }
                    </tbody>
                  </table>
              }
            </div>
          </Tab>
        }
        {hasPermissionC &&
          <Tab eventKey="uploadKeys" title="Upload Keys">
            <div className="tab-content">
              <Button variant="secondary" onClick={() => createUploadKey()}>Create Upload Key</Button>
              <table className="admin-table">
                <thead>
                  <tr>
                    <th>Key</th>
                    <th>Created</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {uploadKeys.map(ukey =>
                    <tr key={ukey.key23}>
                      <td>{ukey.key23}</td>
                      <td>{ukey.createdAtDisplay}</td>
                      <td className="button-bar">
                        <Button variant="secondary" onClick={() => deleteUploadKey(ukey.key23)}>Delete</Button>
                      </td>
                    </tr>)
                  }
                </tbody>
              </table>
            </div>
          </Tab>
        }
        {(hasPermissionC || hasPermissionG) &&
          <Tab eventKey="sites" title="Tenants">
            <div className="tab-content">
              <Button variant="secondary" onClick={() => createSite()}>Create Tenant</Button>
              <table className="admin-table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Endpoints (actual/licensed)</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {[...sites].sort(getSorter('siteName')).map(site =>
                    <tr key={site.siteId}>
                      <td>{site.siteName}</td>
                      <td>{site.actualMachineCount} / {site.licensedMachineCount}</td>
                      <td className="button-bar">
                        <Button variant="secondary" onClick={() => startEditSite(site)}>Edit</Button>
                        {false ? <Button variant="secondary" onClick={() => deleteSite(site)}>Delete</Button> : <></>}
                      </td>
                    </tr>)
                  }
                </tbody>
              </table>
            </div>
          </Tab>
        }
        {hasPermissionR && <Tab eventKey="reports" title="Reports">
          <div className="tab-content">
            <AdminReports />
          </div>
        </Tab>}
      </Tabs>
      <Modal show={accessDialogVisible} animation={false} size="lg">
        <Modal.Title>Access Details</Modal.Title>
        <Modal.Body>
          <AccessForm done={endGrantAccess} />
        </Modal.Body>
      </Modal>
      <Modal show={siteDialogVisible} animation={false} size="lg">
        <Modal.Title>Tenant Details</Modal.Title>
        <Modal.Body>
          {siteToEdit.current && <SiteForm key={editingFormKeyCounter.current} site={siteToEdit.current} done={endEditSite} />}
        </Modal.Body>
      </Modal>
    </div>
  );
}
