import { useEffect, useMemo, useRef, useState } from "react";
import { Button, Modal, NavDropdown } from "react-bootstrap";
import AdminHome from "components/admin/AdminHome";
import { useMsal } from "@azure/msal-react";
import { AccountInfo } from "@azure/msal-browser";
import TheEnvironmentTitle from "./TheEnvironmentTitle";
import { useNavigate } from "react-router-dom";
import { getSorter } from "system/libraries/utilities";
import { useSelector } from "react-redux";
import IRootState from "system/types/store/IRootState";
import { Icon } from "@fluentui/react/lib/Icon";
import { useResponsive, usePageNavigation, useApi } from "system/hooks";
import { getInitials } from "@fluentui/react";
import LogoutIcon from "@mui/icons-material/Logout";

function getAccountEmail(info: AccountInfo | undefined): string {
  if (!info?.idTokenClaims) return "";

  const idToken = info.idTokenClaims as { email: string };
  return idToken.email ?? "";
}

//COMPONENT: displays the logo, contact me link and admin page's icon
const TheHeader = () => {
  const api = useApi();
  const [adminModalVisible, setAdminModalVisible] = useState(false);
  const [userMenuVisible, setUserMenuVisible] = useState(false);
  const msalContext = useMsal();
  const navigate = useNavigate();
  const { navigateToHomePage } = usePageNavigation();
  const { toggleMobileSidebarVisibility, isMobileSidebarVisible } = useResponsive();

  const menuRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && buttonRef.current && !menuRef.current.contains(event.target as Node) && event.target !== buttonRef.current) {
        setUserMenuVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const { tenants, currentTenantId } = useSelector((state: IRootState) => state.config);
  const sortedTenants = useMemo(() => {
    if (!tenants?.length) {
      return [];
    }

    return [...tenants].sort(getSorter("siteName"));
  }, [tenants]);

  const onTenantChange = (tenant: number | string) => {
    navigate(`/${tenant}`);
  };

  const currentTenantName = tenants?.find((x) => x.siteId === currentTenantId)?.siteName ?? "Company";

  return (
    <nav className="navbar">
      <div className="nav-container">
        <div className="logos">
          <div className="logo" onClick={navigateToHomePage}>
            <img alt="logo-name" src="/img/aiden-logo-400-1.png" />
            <TheEnvironmentTitle />
          </div>
        </div>

        <div className="contact">
          {msalContext?.accounts?.length > 0 && (
            <>
              <Icon
                iconName={isMobileSidebarVisible ? "Cancel" : "GlobalNavButton"}
                onClick={() => toggleMobileSidebarVisibility()}
                className="menu"
              />

              {(tenants?.length ?? 0) > 0 && (
                <NavDropdown title={currentTenantName} style={{ zIndex: "9000", width: "33.33%", textAlign: "center" }}>
                  {sortedTenants.map((tenant) => (
                    <NavDropdown.Item
                      className={tenant.siteId === currentTenantId ? "active" : ""}
                      onClick={(e) => {
                        onTenantChange(tenant.siteId);
                        e.preventDefault();
                      }}
                      key={tenant.siteId}
                      href="/#"
                    >
                      {tenant.siteName}
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
              )}

              <div className="account">
                <span className="clickable" onClick={() => setAdminModalVisible(true)}>
                  <i className="bi bi-gear" style={{ fontSize: "16px" }}></i>
                </span>

                <div className="user" onClick={() => setUserMenuVisible(!userMenuVisible)} ref={buttonRef}>
                  {getInitials(msalContext.accounts[0]?.name, false, false)}
                </div>
              </div>
            </>
          )}
        </div>

        {userMenuVisible && (
          <div className="userMenu" ref={menuRef}>
            <div className="userMenu__header">
              <img alt="logo-icon" src="/img/aiden-logo-400-1.png" />
              <div onClick={() => api.auth.logout()}>
                <LogoutIcon fontSize="small" /> Sign out
              </div>
            </div>
            <div className="userMenu__body">
              <div className="user user-menu" onClick={() => setUserMenuVisible(!userMenuVisible)}>
                {getInitials(msalContext.accounts[0]?.name, false, false)}
              </div>
              <div className="userMenu__body--info">
                <span className="userMenu__body--name">{msalContext.accounts[0]?.name}</span>
                <span className="userMenu__body--email">{getAccountEmail(msalContext.accounts[0])}</span>
              </div>
            </div>
          </div>
        )}
      </div>

      <Modal show={adminModalVisible} animation={false} size="lg">
        <Modal.Title>Administration</Modal.Title>
        <Modal.Body>
          <AdminHome />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setAdminModalVisible(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </nav>
  );
};

export default TheHeader;
