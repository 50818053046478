interface IProps {
  code: number;
  isUnknown: boolean;
  isSubject?: boolean;
}

const ComplianceStatus = ({ code, isUnknown, isSubject }: IProps) => {
  const ComplianceSpan = ({ isUnknown, className, text }: { isUnknown: boolean; className: string; text: string }) => {
    return (
      <span className={"state-text summary-" + (isUnknown ? "not-reporting" : className)}>
        {text}
        {isUnknown ? " (not reporting)" : ""}
      </span>
    );
  };

  if (code === 20)
    return <ComplianceSpan isUnknown={isUnknown} className="failed" text={isSubject ? "Critical" : "Critical action required"} />;
  else if (code === 1) return <ComplianceSpan isUnknown={isUnknown} className="failed" text={"Failed to evaluate"} />;
  else if (code === 9) return <ComplianceSpan isUnknown={isUnknown} className="up-to-date" text="Up to date" />;
  else if (code === 8)
    return <ComplianceSpan isUnknown={isUnknown} className="up-to-date-pending-reboot" text="Up to date pending reboot" />;
  else if (code === 7) return <ComplianceSpan isUnknown={isUnknown} className="up-to-date" text="Not needed" />;
  else if (code === 5) return <ComplianceSpan isUnknown={isUnknown} className="has-recommendations" text="Action recommended" />;
  else if (code === 0) return <ComplianceSpan isUnknown={true} className="no-data" text="No data" />;
  return <span>Error checking compliance</span>;
};

export default ComplianceStatus;
