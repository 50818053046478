import { createTheme } from "@mui/material/styles";

export const muiTheme = createTheme({
  // Dark theme configurations
  palette: {
    mode: "dark",
    primary: {
      main: "#5ebce9",
      light: "#004478",
    },
    secondary: {
      main: "#1976D2",
      light: "#004878",
    },
    background: {
      default: "#201F1E", 
    },
    text: {
      primary: "#FFFFFF", 
    },
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          color: "white",
          backgroundColor: "#1b1a19",
          "& .MuiAutocomplete-option": {
            '&:hover, &[aria-selected="true"], &[data-focus="true"]': {
              backgroundColor: "rgba(255, 255, 255, 0.1)",
            },
          },
          "&::-webkit-scrollbar": {
            width: "0.6rem",
            background: "#605e5c",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#cccccc",
            borderRadius: "0.4rem",
          },
          scrollbarWidth: "thin",
          scrollbarColor: "#605e5c transparent",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& fieldset": {
            borderColor: "rgb(208, 208, 208)",
          },
          "&:hover fieldset": {
            borderColor: "white",
          },
          "&.Mui-focused fieldset": {
            borderColor: "white",
          },
        },
        input: {
          color: "white",
          backgroundColor: "#1b1a19",
          padding: "10px 12px",
          fontSize: "14px",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "white",
          fontSize: "14px",
          transform: "translate(14px, 14px) scale(1)", 
          "&.MuiInputLabel-shrink": {
            transform: "translate(14px, -15px) scale(0.75)",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          color: "white",
          backgroundColor: "#1b1a19",
        },
        icon: {
          color: "white",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: "white",
          "&.Mui-selected": {
            backgroundColor: "rgba(255, 255, 255, 0.2)",
          },
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.1)",
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: "white",
        },
      },
    },
  },
});
