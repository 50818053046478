import IConfigState from "system/types/store/IConfigState";

const configState: IConfigState = {
  currentTenantId: null,
  tenants: null,
  userInfo: null,
  defaultTenant: null,
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
};

export default configState;