import Button from "react-bootstrap/Button";
import { useState } from "react";
import { SiteInfo } from "system/types/wireTypes";
import { CustomerLicenseDisplayOption } from "system/types/enums/CustomerLicenseDisplayOption";
import { FormikErrors, useFormik } from "formik";
import useApi from "system/hooks/use-api";
import AskAidenAccess from "system/types/enums/AskAidenAccess";

//COMPONENT: displays one site for editing, and saves changes.
//Caller must change the key of this instance when editing a different site, to force re-init.
interface IProps {
  site: SiteInfo; //if siteId is 0, creates new site
  done: () => void;
}

export default function SiteForm({ site, done }: IProps) {
  const api = useApi();
  const [message, setMessage] = useState("");

  const form = useFormik({
    initialValues: {
      siteName: site.siteName,
      shortName: site.shortName,
      oldDeleteDays: site.oldDeleteDays,
      decommissionDays: site.decommissionDays,
      licensedMachineCount: site.licensedMachineCount,
      licenseDays: site.licenseDays,
      validDays: site.validDays,
      customerShowLicenseFlag: site.customerShowLicenseFlag,
      askAidenAccess: site.askAidenAccess,
      isFileModuleEnabled: site.isFileModuleEnabled,
      isSubjectStatusEnabled: site.isSubjectStatusEnabled,
    },
    validate: (values) => {
      const errors: FormikErrors<SiteInfo> = {};
      if (!values.siteName) errors.siteName = "Required";
      else if (values.siteName.length > 100) errors.siteName = "Up to 100 characters";
      else if (values.siteName.length < 2) errors.siteName = "Must be at least 2 characters";
      if (!values.shortName) errors.shortName = "Required";
      else if (values.shortName.length > 64) errors.shortName = "Up to 64 characters";
      else if (values.shortName.length < 2) errors.shortName = "Must be at least 2 characters";
      if (values.oldDeleteDays < 180) errors.oldDeleteDays = "Must be at least 180";
      if (values.decommissionDays < 7) errors.decommissionDays = "Must be at least 7";
      if (values.licensedMachineCount < 10) errors.licensedMachineCount = "Must be at least 10";
      if (values.licenseDays < 14) errors.licenseDays = "Must be at least 14";
      if (values.validDays < 7) errors.validDays = "Must be at least 7";
      return errors;
    },
    onSubmit: async (values) => {
      const newSite: SiteInfo = {
        siteId: site.siteId,
        siteName: values.siteName,
        shortName: values.shortName,
        oldDeleteDays: values.oldDeleteDays,
        decommissionDays: values.decommissionDays,
        licensedMachineCount: values.licensedMachineCount,
        licenseDays: values.licenseDays,
        validDays: values.validDays,
        actualMachineCount: 0,
        isFileModuleEnabled: values.isFileModuleEnabled,
        isSubjectStatusEnabled: values.isSubjectStatusEnabled,
        customerShowLicenseFlag: +values.customerShowLicenseFlag,
        askAidenAccess: values.askAidenAccess,
      };
      const result = await api.admin.addEditSite(newSite);

      if (result.isSuccess) done();
      else setMessage(result.failMessage ?? result.title ?? "Error");
      form.setSubmitting(false);
    },
  });

  return (
    <div>
      <form className="a-form" onSubmit={form.handleSubmit}>
        <label>Name of tenant</label>
        <input name="siteName" value={form.values.siteName} onChange={form.handleChange} />
        {form.errors.siteName && <span className="warning">{form.errors.siteName}</span>}
        <label>Short name of tenant</label>
        <input name="shortName" value={form.values.shortName} onChange={form.handleChange} />
        {form.errors.shortName && <span className="warning">{form.errors.shortName}</span>}
        <label>Days to retain data</label>
        <input name="oldDeleteDays" type="number" value={form.values.oldDeleteDays} onChange={form.handleChange} />
        {form.errors.oldDeleteDays && <span className="warning">{form.errors.oldDeleteDays}</span>}
        <label>Days of silence which counts as decommissioned machine</label>
        <input name="decommissionDays" type="number" value={form.values.decommissionDays} onChange={form.handleChange} />
        {form.errors.decommissionDays && <span className="warning">{form.errors.decommissionDays}</span>}
        <label>Number of machines licensed</label>
        <input name="licensedMachineCount" type="number" value={form.values.licensedMachineCount} onChange={form.handleChange} />
        {form.errors.licensedMachineCount && <span className="warning">{form.errors.licensedMachineCount}</span>}
        <label>Days over which machine activity counts towards license</label>
        <input name="licenseDays" type="number" value={form.values.licenseDays} onChange={form.handleChange} />
        {form.errors.licenseDays && <span className="warning">{form.errors.licenseDays}</span>}
        <label>Days of silence after which compliance becomes unknown</label>
        <input name="validDays" type="number" value={form.values.validDays} onChange={form.handleChange} />
        {form.errors.validDays && <span className="warning">{form.errors.validDays}</span>}

        <label>Enable Documents Module</label>
        <label>
          <input type="checkbox" name="isFileModuleEnabled" checked={form.values.isFileModuleEnabled} onChange={form.handleChange} />
          {form.values.isFileModuleEnabled ? "Enabled" : "Disabled"}
        </label>

        <label> Enable Subject State Graph</label>
        <label>
          <input type="checkbox" name="isSubjectStatusEnabled" checked={form.values.isSubjectStatusEnabled} onChange={form.handleChange} />
          {form.values.isSubjectStatusEnabled ? "Enabled" : "Disabled"}
        </label>

        <label>Show Customer Licenses</label>
        <select name="customerShowLicenseFlag" value={form.values.customerShowLicenseFlag} onChange={form.handleChange}>
          <option value={CustomerLicenseDisplayOption.DoNotShowLicenseCount}>Do Not Show License Count</option>
          <option value={CustomerLicenseDisplayOption.ShowLicenseCount}>Show License Count</option>
        </select>
        <label>AskAiden access level</label>
        <select name="askAidenAccess" value={form.values.askAidenAccess} onChange={form.handleChange}>
          <option value={AskAidenAccess.NoAccess}>No Access</option>
          <option value={AskAidenAccess.PromptsAccess}>Prompts Access</option>
          <option value={AskAidenAccess.FullAccess}>Full Access</option>
        </select>
      </form>
      <div className="warning">{message}</div>
      <div className="button-bar">
        <Button disabled={form.isSubmitting} onClick={() => form.submitForm()}>
          Save
        </Button>
        <Button variant="secondary" onClick={() => done()}>
          Cancel
        </Button>
      </div>
    </div>
  );
}
