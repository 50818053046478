import { UserInfoResponse } from 'system/types/wireTypes';
import IConfigState from "system/types/store/IConfigState";
import IReducerAction from "system/types/store/IReducerAction";

const configReducers = {
  setCurrentTenantId(state: IConfigState, action: IReducerAction<number>) {
    state.currentTenantId = action.payload ?? null;
  },

  setData(state: IConfigState, action: IReducerAction<UserInfoResponse | undefined>) {
    state.tenants = null;
    state.currentTenantId = null;
    state.userInfo = null;
    state.defaultTenant = null;

    if (!action.payload) {
      return;
    }

    state.tenants = action.payload.tenants;
    state.userInfo = action.payload.userInfo;
    state.defaultTenant = action.payload.defaultTenant;
  }
};

export default configReducers;