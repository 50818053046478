import IMainState from "system/types/store/IMainState";

const mainState : IMainState = {
  pageTitle: '',
  subTitle: '',
  filters: null,
  lastFilterSet: (new Date()).getTime(),
  breadcrumbs: [],
  isMobileSidebarVisible: false
};

export default mainState;